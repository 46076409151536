/* eslint-disable max-params */

import moment from 'moment'
import { MessageIdType } from 'locales/types'
import { DateRangeFilter } from '../components/frappuccino/types'

export const CAMPAIGN_OBJECTIVE_FIELD_VALUES = {
  reach: 'GOAL_REACH',
  engage: 'GOAL_ENGAGE',
  maximizeSales: 'GOAL_MAXIMIZE_SALES',
  acquire: 'GOAL_ACQUIRE',
  custom: 'GOAL_CUSTOM',
}

export const CAMPAIGN_FORMAT_FIELD_VALUES = {
  sponsoredProduct: 'GOAL_FORMAT_SPONSORED_PRODUCT',
  shoppableDisplay: 'GOAL_FORMAT_SHOPPABLE_DISPLAY',
  shoppableDisplayRecipe: 'GOAL_FORMAT_SHOPPABLE_DISPLAY_RECIPE',
  shoppableDisplayUrlClick: 'GOAL_FORMAT_SHOPPABLE_DISPLAY_URL_CLICK',
  displayBanner: 'GOAL_FORMAT_DISPLAY_BANNER',
  displayBannerUrlClick: 'GOAL_FORMAT_DISPLAY_BANNER_URL_CLICK',
  shoppableVideo: 'GOAL_FORMAT_SHOPPABLE_VIDEO',
  shoppableVideoRecipe: 'GOAL_FORMAT_SHOPPABLE_VIDEO_RECIPE',
  shoppableVideoUrlClick: 'GOAL_FORMAT_SHOPPABLE_VIDEO_URL_CLICK',
  inspiration: 'GOAL_FORMAT_INSPIRATION',
  smart: 'GOAL_FORMAT_SMART',
}

/**
 * Computes the suggested lifetime budget of a Campaign.
 */
export const getSuggestedLifetimeBudget = (
  budgetSuggestion: string,
  spendCents: string,
  startsAt: moment.Moment,
  endsAt: moment.Moment
) => {
  const spendDollars = Number(spendCents) / 100
  const closestStartDay = moment.max([startsAt, moment()])
  const endsDate = endsAt.endOf('day')
  const remainingDays = Math.max(1, Math.ceil(endsDate.diff(closestStartDay, 'days', true)))
  return spendDollars + Number(budgetSuggestion) * remainingDays
}

export const isCampaignSpending = (campaignStatus: string) =>
  ['active', 'scheduled'].includes(campaignStatus)

export const formatToFormattedString = (format?: string): MessageIdType => {
  const formatted: Record<string, MessageIdType> = {
    [CAMPAIGN_FORMAT_FIELD_VALUES.shoppableDisplay]: 'common.goalFormats.shoppableDisplay',
    [CAMPAIGN_FORMAT_FIELD_VALUES.shoppableDisplayRecipe]:
      'common.goalFormats.shoppableDisplayRecipe',
    [CAMPAIGN_FORMAT_FIELD_VALUES.shoppableDisplayUrlClick]:
      'common.goalFormats.shoppableDisplayUrlClick',
    [CAMPAIGN_FORMAT_FIELD_VALUES.shoppableVideo]: 'common.goalFormats.shoppableVideo',
    [CAMPAIGN_FORMAT_FIELD_VALUES.shoppableVideoRecipe]: 'common.goalFormats.shoppableVideoRecipe',
    [CAMPAIGN_FORMAT_FIELD_VALUES.shoppableVideoUrlClick]:
      'common.goalFormats.shoppableVideoUrlClick',
    [CAMPAIGN_FORMAT_FIELD_VALUES.sponsoredProduct]: 'common.goalFormats.sponsoredProduct',
    [CAMPAIGN_FORMAT_FIELD_VALUES.displayBanner]: 'common.goalFormats.displayBanner',
    [CAMPAIGN_FORMAT_FIELD_VALUES.displayBannerUrlClick]:
      'common.goalFormats.displayBannerUrlClick',
    [CAMPAIGN_FORMAT_FIELD_VALUES.inspiration]: 'common.goalFormats.inspiration',
  }

  return formatted[format ?? ''] ?? 'common.na'
}

export const goalToFormattedString = (goal?: string): MessageIdType => {
  const formatted: Record<string, MessageIdType> = {
    [CAMPAIGN_OBJECTIVE_FIELD_VALUES.custom]: 'common.goal.custom',
    [CAMPAIGN_OBJECTIVE_FIELD_VALUES.reach]: 'common.goal.reach',
    [CAMPAIGN_OBJECTIVE_FIELD_VALUES.engage]: 'common.goal.engage',
    [CAMPAIGN_OBJECTIVE_FIELD_VALUES.maximizeSales]: 'common.goal.maximizeSales',
    [CAMPAIGN_OBJECTIVE_FIELD_VALUES.acquire]: 'common.goal.acquire',
  }

  return formatted[goal ?? ''] ?? 'common.na'
}

export const dateRangeFilters: DateRangeFilter[] = [
  {
    column: 'campaign.starts_at_sentinel',
    value: 'endDate',
    operation: 'LTE',
  },
  {
    column: 'campaign.ends_at_sentinel',
    value: 'startDate',
    operation: 'GTE',
  },
]
